<template>
    <section class="main">
        <div id="dib-posts"></div>
    </section>
</template>

<script>
export default {
    name: 'BlogPage',
    data() {
        return {
            jsonFile: '',
        }
    },
    components: {},
    computed:{
        head(){
            return this.jsonFile?.head
        },
        lang() {
            return this.$i18n.locale
        },
    },
    mounted() {
        let sr = document.getElementById('blogScript');
        if(sr) sr.remove();
        const s = document.createElement("script");
        s.src = 'https://io.dropinblog.com/embedjs/575e2d42-caa3-48aa-8956-f9baca31711b.js';
        s.setAttribute('id', 'blogScript')
        document.body.appendChild(s);
    },
    created() {
        // this.jsonFile = require('../../pages/pro-hub.json')
    }
}
</script>
<style lang="scss" scoped>
.main {
    height: 100%;
}
@media all and (max-width: 1550px) {
    .main {
    }
}
</style>